export default {
  "cell-content": "cjW",
  "icon": "cju",
  "status_check-m": "cWS",
  "description": "cWc body-1",
  "from": "cWq body-2",
  "from--abort": "cWZ",
  "description--abort": "cWR",
  "title": "cWQ body-2"
};
