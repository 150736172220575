export default {
  "row": "qZC",
  "active": "qZk",
  "animated": "qZJ",
  "fadein-item": "qZs",
  "cell": "qZF body-2",
  "cell-content": "qZg",
  "subtitle": "qZD caption",
  "no-padding": "qZV",
  "note": "qZL",
  "cell-amount": "qZT",
  "cell-content-amount": "qZz body-1"
};
