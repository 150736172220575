/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ShowMembers } from 'qonto/react/components/show-members';

export default class TeamCardComponent extends Component {
  @service modals;
  @service segment;
  @service organizationManager;
  @service router;

  showMembers = ShowMembers;

  get hasAllMembersRevoked() {
    return (
      this.args.memberships.length && this.args.memberships.every(membership => membership.revoked)
    );
  }

  @action
  renameTeam() {
    this.segment.track('teams_rename_clicked');
    this.modals.open('teams/modal/rename-team', {
      team: this.args.team,
    });
  }

  @action
  viewTeamMemberships() {
    this.router.transitionTo('members', {
      queryParams: { teamId: this.args.team.id },
    });
  }

  @action
  deleteTeam() {
    this.segment.track('teams_delete_clicked');

    if (this.args.memberships.length) {
      return this.modals.open('teams/modal/refuse-delete-team', {
        hasAllMembersRevoked: this.hasAllMembersRevoked,
        teamId: this.args.team.id,
        viewTeamMemberships: this.viewTeamMemberships,
      });
    }

    this.modals.open('teams/modal/confirm-delete-team', {
      team: this.args.team,
      confirmTask: this.args.onTeamDeleted,
    });
  }
}
