export default {
  "row": "cfn",
  "active": "cfi",
  "animated": "cfA",
  "fadein-item": "cfY",
  "cell": "cfh",
  "cell-content": "cff",
  "row-sidebar": "cfK",
  "empty": "cfG",
  "cell-amount": "cfr",
  "subtitle": "cfb caption",
  "hidden": "cfy",
  "cell-approve": "cfU",
  "quick-actions": "cfj",
  "account-select": "cfW",
  "note": "cfu",
  "cell-content-amount": "cKS body-1",
  "cell-status": "cKc"
};
