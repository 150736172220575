export default {
  "wrapper": "chT",
  "tabs": "chz",
  "tabs-nav": "cha",
  "tabs-panel": "chH",
  "tabs-panel-wrapper": "chO",
  "list-head": "chm",
  "list": "chp",
  "footer": "chx",
  "reward-amount": "chw",
  "rewarded": "chN",
  "registered": "chv",
  "disclaimer": "cho",
  "empty-state-wrapper": "chn",
  "empty-state": "chi",
  "illustration": "chA",
  "spinner": "chY",
  "total-earned": "chh"
};
