export default {
  "insight-pill": "qPE",
  "insight-pill-figure": "qPd",
  "insight-pill-renderer": "qPI",
  "loading": "qPt",
  "counter": "qPM",
  "error": "qPP",
  "insight-pill-label": "qPl",
  "insight-pill-subtitle": "qPX caption",
  "hidden": "qPC",
  "missing-amounts-warning": "qPk",
  "missing-amounts": "qPJ"
};
