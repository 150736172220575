export default {
  "header-cell": "cyD",
  "empty": "cyV",
  "header-content": "cyL caption-bold",
  "active": "cyT",
  "header-type": "cyz",
  "header-reason": "cya",
  "header-status": "cyH",
  "header-amount": "cyO",
  "row-sidebar": "cym",
  "hidden": "cyp",
  "animated": "cyx",
  "fadein-item": "cyw",
  "animated-cell": "cyN"
};
