export default {
  "wrapper": "cjm",
  "request-header": "cjp",
  "request": "cjx",
  "scheduled": "cjw",
  "scheduled-label": "cjN",
  "note": "cjv",
  "note-label": "cjo",
  "note-label-danger": "cjn",
  "note-content": "cji",
  "status": "cjA",
  "transfer-amount": "cjY"
};
