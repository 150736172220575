export default {
  "referral-invite": "chf",
  "referral": "chK",
  "illustration": "chG",
  "steps": "chr",
  "step": "chb",
  "share-referral": "chy",
  "choice": "chU",
  "line": "chj",
  "email": "chW",
  "email-input": "chu",
  "input-field--error": "cfS",
  "error-message": "cfc",
  "external-link-icon": "cfq"
};
