export default {
  "row": "qkE",
  "active": "qkd",
  "dot": "qkI",
  "red": "qkt",
  "green": "qkM",
  "yellow": "qkP",
  "align-right": "qkl",
  "animated": "qkX",
  "fadein-item": "qkC",
  "cell": "qkk body-2",
  "cell-content": "qkJ",
  "amount": "qks body-1",
  "subtitle": "qkF caption",
  "status": "qkg",
  "cell-amount": "qkD"
};
