export default {
  "iban": "qRd",
  "checkbox": "qRI",
  "toggle-content": "qRt",
  "beneficiary-name": "qRM",
  "category": "qRP",
  "beneficiary-email": "qRl",
  "beneficiary-form": "qRX",
  "beneficiary-email-container": "qRC"
};
