export default {
  "row": "qkA",
  "icon": "qkY",
  "cell": "qkh",
  "cell-date": "qkf",
  "cell-amount": "qkK",
  "cell-status": "qkG",
  "cell-content": "qkr",
  "align-right": "qkb"
};
