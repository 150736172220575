export default {
  "progress-container": "qJV",
  "dashed-line": "qJL",
  "solid-line": "qJT",
  "circle-container": "qJz",
  "circle": "qJa",
  "current-step-dot": "qJH",
  "last-step-dot": "qJO",
  "success-icon": "qJm",
  "rejected-icon": "qJp"
};
