export default {
  "row": "cWe",
  "empty": "cWB",
  "active": "cWE",
  "animated": "cWd",
  "fadein-item": "cWI",
  "cell": "cWt body-2",
  "cell-content": "cWM",
  "cell-amount": "cWP",
  "cell-content-amount": "cWl body-1"
};
