export default {
  "insight-pill": "qMj",
  "interactive": "qMW",
  "focus": "qMu",
  "subtitle": "qPS caption",
  "hidden": "qPc",
  "missing-amounts-warning": "qPq",
  "totals": "qPZ",
  "counter": "qPR title-3",
  "loading": "qPQ",
  "error": "qPe",
  "title": "qPB title-4"
};
