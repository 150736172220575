export default {
  "attachment-viewer": "cuQ",
  "hide-sidebar": "cue",
  "sidebar": "cuB",
  "header": "cuE",
  "header-top": "cud",
  "header-icon": "cuI",
  "header-amount": "cut",
  "infobox": "cuM",
  "vat": "cuP",
  "validation": "cul"
};
