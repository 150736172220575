export default {
  "wrapper": "cfd",
  "tabs": "cfI",
  "tabs-nav": "cft",
  "tabs-panel-wrapper": "cfM",
  "list-head": "cfP",
  "list": "cfl",
  "footer": "cfX",
  "reward-amount": "cfC",
  "earned": "cfk",
  "pending": "cfJ",
  "empty-state-wrapper": "cfs",
  "empty-state": "cfF",
  "illustration": "cfg",
  "error-wrapper": "cfD",
  "error-illustration": "cfV",
  "error-description": "cfL",
  "spinner": "cfT",
  "total-earned": "cfz"
};
