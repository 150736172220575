export default {
  "row": "qXs",
  "cell": "qXF",
  "supplier": "qXg",
  "email-address": "qXD",
  "outstanding-balance": "qXV",
  "quick-actions": "qXL",
  "empty": "qXT",
  "supplier-container": "qXz",
  "supplier-avatar": "qXa",
  "grey-background": "qXH"
};
