export default {
  "header": "crw",
  "header-type": "crN",
  "close": "crv",
  "close-icon": "cro",
  "header-infos": "crn",
  "title": "cri body-1",
  "declined": "crA",
  "canceled": "crY",
  "approved": "crh",
  "pending": "crf",
  "date": "crK body-2",
  "container": "crG",
  "picto": "crr",
  "picto-status": "crb",
  "general": "cry",
  "amount": "crU",
  "counterparty": "crj body-2",
  "initiator": "crW",
  "initiator-avatar": "cru",
  "avatar-image": "cbS",
  "initiator-id": "cbc",
  "name": "cbq body-2",
  "role": "cbZ caption-bold",
  "infobox": "cbR",
  "account-infobox": "cbQ cbR",
  "beneficiary-warning": "cbe caption-bold",
  "dates-wrapper": "cbB",
  "dates": "cbE",
  "recurrence": "cbd caption-bold",
  "arrow": "cbI",
  "notify-checkbox": "cbt"
};
