export default {
  "header": "cKH",
  "header-type": "cKO",
  "close": "cKm",
  "close-icon": "cKp",
  "header-infos": "cKx",
  "title": "cKw body-1",
  "declined": "cKN",
  "canceled": "cKv",
  "approved": "cKo",
  "pending": "cKn",
  "date": "cKi body-2",
  "mileage-icon": "cKA",
  "distance-subtitle": "cKY body-2",
  "link-icon": "cKh",
  "container": "cKf",
  "picto": "cKK",
  "general": "cKG",
  "amount": "cKr",
  "calculation-container": "cKb",
  "counterparty": "cKy body-2",
  "infobox": "cKU"
};
