export default {
  "wrapper": "qRw",
  "beneficiary": "qRN",
  "name": "qRv body-1",
  "beneficiary-email": "qRo body-2",
  "dropdown-icon": "qRn",
  "trusted": "qRi",
  "account-number": "qRA flex body-2",
  "account-icon": "qRY",
  "m-chip": "qRh",
  "dropdown": "qRf body-2",
  "delete-option": "qRK",
  "beneficiary-name": "qRG"
};
