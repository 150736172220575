export default {
  "savings-account": "qeQ",
  "closed": "qee",
  "badge": "qeB",
  "subtitle": "qeE",
  "amount": "qed",
  "processing": "qeI",
  "gain": "qet",
  "active": "qeM",
  "progress": "qeP",
  "progress-bar": "qel",
  "progress-text": "qeX",
  "info": "qeC",
  "menu-item": "qek body-2",
  "close-account": "qeJ"
};
