export default {
  "modal-wrapper": "cAv",
  "spinner-container": "cAo",
  "spinner": "cAn",
  "container": "cAi",
  "tabs-item": "cAA",
  "form-container": "cAY",
  "fieldset": "cAh",
  "preview-container": "cAf",
  "switch-container": "cAK",
  "pdf-preview-container": "cAG",
  "form-content": "cAr",
  "row": "cAb",
  "numbering-format": "cAy",
  "numbering-fields": "cAU",
  "input-settings": "cAj",
  "next-invoice-number": "cAW",
  "spacing": "cAu",
  "label-tooltip": "cYS",
  "tooltip-icon": "cYc",
  "customize-field": "cYq",
  "button-container-footer": "cYZ"
};
