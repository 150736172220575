export default {
  "header-cell": "cYO",
  "empty": "cYm",
  "header-content": "cYp caption-bold",
  "active": "cYx",
  "align-right": "cYw",
  "col-8": "cYN",
  "col-7": "cYv",
  "col-5": "cYo",
  "col-4": "cYn"
};
