export default {
  "section-container": "qSl",
  "disclaimer-section": "qSX",
  "trip-title": "qSC caption-bold mb-16",
  "google-link-container": "qSk",
  "link-icon": "qSJ",
  "trip-details": "qSs body-2",
  "trip-details-icon": "qSF",
  "trip-details-title": "qSg",
  "subtitle": "qSD caption-bold mb-16",
  "disclaimer": "qSV",
  "visualizer": "qSL"
};
