export default {
  "form-content": "cYR",
  "row": "cYQ",
  "numbering-format": "cYe",
  "numbering-fields": "cYB",
  "input-settings": "cYE",
  "next-invoice-number": "cYd",
  "spacing": "cYI",
  "label-tooltip": "cYt",
  "tooltip-icon": "cYM",
  "customize-field": "cYP"
};
