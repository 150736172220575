export default {
  "details": "qtf",
  "details-header": "qtK",
  "details-content": "qtG",
  "details-footer": "qtr",
  "invoice-status-container": "qtb body-2",
  "invoice-payment-date": "qty",
  "overdue": "qtU",
  "details-list": "qtj",
  "details-list-item": "qtW",
  "details-list-item-label": "qtu body-2",
  "details-list-item-value": "qMS",
  "border-top": "qMc",
  "description": "qMq",
  "description-value": "qMZ",
  "edit-button": "qMR body-2",
  "edit-icon": "qMQ",
  "icon": "qMe"
};
