export default {
  "invoice-sidebar": "qEp",
  "body": "qEx",
  "box": "qEw",
  "box-header": "qEN",
  "sidebar-box": "qEv",
  "border-top": "qEo",
  "row": "qEn",
  "greyed-out": "qEi",
  "strike-through": "qEA"
};
