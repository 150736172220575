/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/requests';
import { generateGoogleMapsLink } from 'qonto/utils/generate-google-maps-link';
import { getVehicleTypes } from 'qonto/utils/mileage/vehicle-types';

export default class ReimbursementsRequestsSidebarMileageDetailsComponent extends Component {
  @service intl;
  @service abilities;
  @service organizationManager;

  get googleMapsLink() {
    let { departure, arrival, roundTrip } = this.args.request;
    return generateGoogleMapsLink(departure, arrival, roundTrip);
  }

  get distanceInKm() {
    return (this.args.request.mileageCalculation.distanceMeters / 1000).toFixed(2);
  }

  fiscalPowerLabel(powerLabel) {
    let { electric } = this.args.request.vehicle;

    if (electric)
      return this.intl.t('requests.mileage.sidebar.vehicle-type.electric-fiscal-power', {
        fiscal_power: powerLabel,
      });

    return `(${this.intl.t('requests.mileage.steps.request-details.fiscal-power', {
      fiscal_power: powerLabel,
    })})`;
  }

  get vehicleFiscalPower() {
    let { legalCountry } = this.organizationManager.organization;

    let vehicles = getVehicleTypes({ intl: this.intl, legalCountry });
    let { type, power, electric } = this.args.request.vehicle;

    let vehicleLabel = vehicles[type].label;

    if (!power) {
      if (!electric) return vehicleLabel;
      return `${vehicleLabel} ${this.intl.t('requests.mileage.sidebar.vehicle-type.electric')}`;
    }

    let powerOption = vehicles[type].powerOptions.find(option => option.value === power);

    let powerLabel = powerOption.label;

    return `${vehicleLabel} ${this.fiscalPowerLabel(powerLabel)}`;
  }

  get showDisclaimer() {
    let { request } = this.args;
    let userId = request.get('initiator.userId');

    return (
      request.status === STATUS.PENDING &&
      this.abilities.can('cancel mileage request', userId) &&
      this.abilities.cannot('see state approval-workflow')
    );
  }

  get shouldDisplayApprovalWorkflowState() {
    let { approvalWorkflowState, canceled } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');
    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    let { approvalWorkflowState } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }
}
