export default {
  "row": "qIQ",
  "disabled": "qIe",
  "cell": "qIB body-2",
  "cell-link": "qIE",
  "cell-content": "qId",
  "item-truncate-text": "qII",
  "item-name-cell": "qIt",
  "item-account-cell": "qIM",
  "icon": "qIP",
  "icon-download": "qIl"
};
