export default {
  "item": "qPh",
  "details": "qPf",
  "flex": "qPK",
  "header": "qPG qPK",
  "flex-no-gap": "qPr qPK",
  "file-name": "qPb",
  "label": "qPy body-2",
  "self-icon": "qPU",
  "with-separator": "qPj",
  "dimmed": "qPW"
};
