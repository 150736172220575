export default {
  "request-transfer-validation": "qQI",
  "account-selector": "qQt",
  "has-error": "qQM",
  "actions": "qQP",
  "action-btn": "qQl",
  "warnings": "qQX",
  "top-border": "qQC",
  "account-selector-subtitle": "qQk",
  "action-btn-right": "qQJ",
  "funds-disclaimer": "qQs",
  "account-options": "qQF",
  "account-balance": "qQg",
  "request-multi-transfer-validation": "qQD",
  "wrapper": "qQV",
  "warning-message-row": "qQL",
  "multi-transfer-align": "qQT",
  "button-approve": "qQz",
  "button-approve-spinner": "qQa",
  "button-decline": "qQH"
};
