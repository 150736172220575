export default {
  "review": "qeG",
  "review-content": "qer",
  "review-parameters": "qeb",
  "row": "qey",
  "row__border": "qeU",
  "label": "qej",
  "value": "qeW",
  "error": "qeu",
  "tooltip-icon": "qBS",
  "terms-link": "qBc"
};
