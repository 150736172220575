export default {
  "header": "cug",
  "header-type": "cuD",
  "close": "cuV",
  "close-icon": "cuL",
  "header-infos": "cuT",
  "title": "cuz body-1",
  "declined": "cua",
  "canceled": "cuH",
  "approved": "cuO",
  "pending": "cum",
  "date": "cup body-2",
  "container": "cux",
  "picto": "cuw",
  "picto-status": "cuN",
  "general": "cuv",
  "amount": "cuo",
  "counterparty": "cun body-2",
  "initiator": "cui",
  "initiator-avatar": "cuA",
  "avatar-image": "cuY",
  "initiator-id": "cuh",
  "name": "cuf body-2",
  "role": "cuK caption-bold",
  "infobox": "cuG",
  "account-infobox": "cur cuG",
  "beneficiary-warning": "cub caption-bold",
  "dates-wrapper": "cuy",
  "dates": "cuU",
  "recurrence": "cuj caption-bold",
  "arrow": "cuW",
  "notify-checkbox": "cuu"
};
