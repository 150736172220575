export default {
  "item-wrapper": "qIU",
  "title-sub-wrapper": "qIj",
  "icon-title-wrapper": "qIW",
  "icon": "qIu",
  "icon-wrapper": "qtS",
  "title": "qtc body-1",
  "subtitle": "qtq body-2",
  "amount": "qtZ body-1"
};
