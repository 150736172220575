export default {
  "header": "cbw",
  "header-type": "cbN",
  "close": "cbv",
  "close-icon": "cbo",
  "header-infos": "cbn",
  "title": "cbi body-1",
  "declined": "cbA",
  "canceled": "cbY",
  "approved": "cbh",
  "pending": "cbf",
  "date": "cbK body-2",
  "mileage-icon": "cbG",
  "distance-subtitle": "cbr body-2",
  "link-icon": "cbb",
  "container": "cby",
  "picto": "cbU",
  "general": "cbj",
  "amount": "cbW",
  "calculation-container": "cbu",
  "counterparty": "cyS body-2",
  "infobox": "cyc"
};
