export default {
  "row": "cKq",
  "icon": "cKZ",
  "cell": "cKR",
  "cell-amount": "cKQ",
  "cell-status": "cKe",
  "cell-date": "cKB",
  "cell-content": "cKE",
  "align-right": "cKd"
};
