export default {
  "header": "qcJ",
  "header-type": "qcs",
  "close": "qcF",
  "close-icon": "qcg",
  "header-infos": "qcD",
  "title": "qcV body-1",
  "declined": "qcL",
  "canceled": "qcT",
  "approved": "qcz",
  "date": "qca body-2",
  "container": "qcH",
  "picto": "qcO",
  "request": "qcm",
  "picto-status": "qcp",
  "check": "qcx",
  "stop": "qcw",
  "warning": "qcN",
  "general": "qcv",
  "amount": "qco",
  "counterparty": "qcn body-2",
  "initiator": "qci",
  "initiator-avatar": "qcA",
  "avatar-image": "qcY",
  "initiator-id": "qch",
  "name": "qcf body-2",
  "role": "qcK caption-bold",
  "infobox": "qcG",
  "account-infobox": "qcr qcG",
  "beneficiary-warning": "qcb caption-bold",
  "dates-wrapper": "qcy",
  "dates": "qcU",
  "recurrence": "qcj caption-bold",
  "arrow": "qcW",
  "notify-checkbox": "qcu"
};
