export default {
  "referral-invite-new": "chB",
  "referral": "chE",
  "center": "chd",
  "illustration": "chI",
  "share-referral": "cht",
  "choice": "chM",
  "line": "chP",
  "email": "chl",
  "email-input": "chX",
  "input-field--error": "chC",
  "error-message": "chk",
  "external-link-icon": "chJ"
};
