export default {
  "label-select": "qdi",
  "puce": "qdA",
  "puce-wrapper": "qdY",
  "puce-container": "qdh",
  "readonly": "qdf",
  "label-display": "qdK",
  "label-list-name": "qdG body-1",
  "label-list-value": "qdr body-2"
};
