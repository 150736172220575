export default {
  "header-cell": "cGL",
  "empty": "cGT",
  "header-type": "cGz",
  "header-requester": "cGa",
  "header-date": "cGH",
  "header-amount": "cGO",
  "header-approval": "cGm",
  "header-content": "cGp caption-bold",
  "active": "cGx",
  "header-receipt": "cGw",
  "row-compact": "cGN",
  "row-sidebar": "cGv",
  "hidden": "cGo",
  "animated": "cGn",
  "fadein-item": "cGi",
  "animated-cell": "cGA"
};
