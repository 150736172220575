export default {
  "card-details-container": "cWL",
  "title": "cWT caption-bold mb-16",
  "section": "cWz",
  "divider": "cWa",
  "top-divider": "cWH",
  "content": "cWO body-2",
  "description": "cWm",
  "justify-end": "cWp",
  "icon": "cWx ml-8"
};
