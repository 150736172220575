export default {
  "sections": "qRT",
  "actions": "qRz",
  "search-input": "qRa",
  "no-upload": "qRH",
  "new-link": "qRO body-2",
  "between-links": "qRm",
  "action-bar": "qRp",
  "empty-state": "qRx"
};
