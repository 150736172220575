export default {
  "section-container": "cKk",
  "disclaimer-section": "cKJ",
  "trip-title": "cKs caption-bold mb-16",
  "google-link-container": "cKF",
  "link-icon": "cKg",
  "trip-details": "cKD body-2",
  "trip-details-icon": "cKV",
  "trip-details-title": "cKL",
  "subtitle": "cKT caption-bold mb-16",
  "disclaimer": "cKz",
  "visualizer": "cKa"
};
