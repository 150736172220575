export default {
  "mileage-validation": "qSu",
  "actions": "qcS",
  "action-btn": "qcc",
  "approve-btn": "qcq",
  "request-mileage-validation": "qcZ",
  "account-selector": "qcR",
  "has-error": "qcQ",
  "funds-disclaimer": "qce",
  "account-options": "qcB",
  "account-balance": "qcE",
  "warnings": "qcd"
};
