export default {
  "modal": "qBG",
  "container": "qBr",
  "form-container": "qBb",
  "preview-container": "qBy",
  "preview-content": "qBU",
  "switch-container": "qBj",
  "supplier-invoice-preview": "qBW",
  "header": "qBu",
  "footer": "qES",
  "form-content": "qEc",
  "form-content-section": "qEq",
  "form-disclaimer": "qEZ",
  "hidden": "qER"
};
