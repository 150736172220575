export default {
  "team-card": "qJQ",
  "card-link": "qJe",
  "shadow": "qJB",
  "history-button": "qJE",
  "cards-button": "qJd",
  "menu-item": "qJI",
  "subtitle": "qJt",
  "delete-team": "qJM"
};
