export default {
  "header-cell": "cfH",
  "header-content": "cfO caption-bold",
  "active": "cfm",
  "empty": "cfp",
  "request-type": "cfx",
  "date": "cfw",
  "requester": "cfN",
  "amount": "cfv",
  "header-status": "cfo"
};
