export default {
  "row": "cYY",
  "cell": "cYh",
  "greyed-out": "cYf",
  "struck-through": "cYK",
  "flex-cell": "cYG",
  "overdue": "cYr",
  "align-right": "cYb",
  "customer-name-container": "cYy",
  "icon": "cYU",
  "warning": "cYj",
  "with-separator": "cYW",
  "align-center": "cYu"
};
