export default {
  "wrapper": "qBg",
  "content": "qBD",
  "title": "qBV",
  "aside": "qBL",
  "description": "qBT",
  "faq-link": "qBz",
  "close-btn": "qBa",
  "container": "qBH",
  "mandate-field-list": "qBO",
  "mandate-field-item": "qBm",
  "mandate-field-item__dot": "qBp",
  "mandate-field-item__text": "qBx"
};
