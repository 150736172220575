export default {
  "row": "cGY body-1",
  "active": "cGh",
  "cell": "cGf",
  "empty": "cGK",
  "ellipsis": "cGG",
  "text-secondary": "cGr",
  "transfer-type": "cGb",
  "transfer-type__icon": "cGy",
  "quick-actions": "cGU",
  "amount": "cGj",
  "cell-approve": "cGW",
  "account-select": "cGu",
  "row-compact": "crS",
  "cell-amount": "crc",
  "cell-content-receipt": "crq body-1",
  "cell-date": "crZ",
  "row-sidebar": "crR",
  "hidden": "crQ",
  "animated": "cre",
  "fadein-item": "crB",
  "animated-cell": "crE",
  "note": "crd"
};
