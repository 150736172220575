export default {
  "card-sidebar-header": "cWw",
  "content": "cWN",
  "header-type": "cWv",
  "close": "cWo",
  "close-icon": "cWn",
  "header-infos": "cWi",
  "title": "cWA body-1",
  "danger": "cWY",
  "success": "cWh",
  "warning": "cWf",
  "date": "cWK body-2",
  "card-image-container": "cWG",
  "card-image": "cWr",
  "info-container": "cWb",
  "spending-reason": "cWy",
  "spending-reason-label": "cWU body-2 mr-8",
  "spending-reason-note": "cWj body-2"
};
