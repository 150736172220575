export default {
  "header-cell": "qqr",
  "header-content": "qqb caption-bold",
  "active": "qqy",
  "empty": "qqU",
  "request-type": "qqj",
  "status": "qqW",
  "note": "qqu",
  "amount": "qZS"
};
