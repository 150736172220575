export default {
  "content": "cjR",
  "balance": "cjQ",
  "duration": "cje",
  "green-text": "cjB",
  "balance-subtitle": "cjE",
  "balance-duration": "cjd",
  "footer": "cjI",
  "progress": "cjt",
  "progress-bar": "cjM",
  "progress-bar-text": "cjP",
  "progress-active": "cjl"
};
