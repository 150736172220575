export default {
  "form": "qMT",
  "form-section": "qMz",
  "form-title": "qMa title2",
  "divider": "qMH",
  "row": "qMO",
  "col": "qMm",
  "actions": "qMp",
  "buttons": "qMx"
};
