export default {
  "attachment-viewer": "crk",
  "hide-sidebar": "crJ",
  "sidebar": "crs",
  "header": "crF",
  "header-top": "crg",
  "header-icon": "crD",
  "header-amount": "crV",
  "infobox": "crL",
  "vat": "crT",
  "validation": "crz"
};
