export default {
  "wrapper": "qRj",
  "header": "qRW mb-48",
  "form-title": "qRu title-2 mb-16",
  "form-subtitle": "qQS body-2",
  "form": "qQc",
  "section": "qQq mb-48",
  "section-title": "qQZ title-3 mb-16",
  "form-footer": "qQR",
  "footer-btn": "qQQ",
  "transfer-form": "qQe"
};
