/* import __COLOCATED_TEMPLATE__ from './created.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';

import { Tag } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  INVOICED_STATUS,
  LOCAL_STORAGE_WARNING_DISMISSED_KEY,
  SENTRY_IGNORE_HTTP_STATUSES,
  STATUS,
} from 'qonto/constants/receivable-invoice';
import { importAsset } from 'qonto/helpers/import-asset';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

import ReceivableInvoicesSidebarSharedComponent from './sidebar-shared';

export default class ReceivableInvoicesInvoiceModalSidebarCreatedComponent extends ReceivableInvoicesSidebarSharedComponent {
  tag = Tag;

  @service intl;
  @service modals;
  @service toastFlashMessages;
  @service sentry;
  @service segment;
  @service router;
  @service organizationManager;
  @service store;
  @service notifierManager;
  @service subscriptionManager;

  get dueOrExpiryDate() {
    if (this.isQuote) {
      return this.invoicingDocument.expiryDate;
    }
    return this.invoicingDocument.dueDate;
  }

  get shouldDisplayPerformanceDate() {
    return this.args.invoicingDocument.performanceDate && !this.args.isQuote;
  }

  get shouldDisplayNextReminderDate() {
    return (
      this.invoicingDocument?.status === STATUS.UNPAID && this.invoicingDocument?.nextReminderDate
    );
  }

  get shouldDisplayRemindersCTA() {
    if (!variation('feature--boolean-reminders-invoice-entry-point')) {
      return false;
    }

    let invoiceIsUnpaid = this.invoicingDocument?.status === STATUS.UNPAID;
    let isSoloBasicPlan = this.subscriptionManager.currentPricePlan?.groupCode === 'solo_basic';

    return invoiceIsUnpaid && !isSoloBasicPlan;
  }

  get remindersCTAText() {
    let customer = this.invoicingDocument?.belongsTo('customer');

    if (!customer) {
      return this.intl.t('receivable-invoices.invoice-modal.setup-payment-reminders');
    }

    let client = this.store.peekRecord('client-hub', customer.id());

    return client?.hasReminders
      ? this.intl.t('receivable-invoices.invoice-modal.edit-payment-reminders')
      : this.intl.t('receivable-invoices.invoice-modal.setup-payment-reminders');
  }

  get isNotCurrencyMismatch() {
    return this.invoicingDocument?.invoicedStatus !== INVOICED_STATUS.CURRENCY_MISMATCH;
  }

  get createInvoiceTooltip() {
    if (this.args.isGenerateInvoiceDisabled) {
      return this.intl.t(
        'receivable-invoices.invoice-modal.actions.create-invoice.basic-plan-tooltip'
      );
    }
  }

  get showRelatedDocuments() {
    if (this.isQuote) {
      return this.invoicingDocument.hasMany('receivableInvoices').ids().length > 0;
    }
    return this.invoicingDocument.receivableCreditNotes.length > 0 || this.invoicingDocument.quote;
  }

  get isEditable() {
    let editableStatus = ['pending_approval', 'draft'];
    return editableStatus.includes(this.invoicingDocument?.status);
  }

  get hasFooterActions() {
    let quoteStatus = ['pending_approval', 'approved'];
    let invoiceStatus = ['draft', 'unpaid'];
    return (
      quoteStatus.includes(this.invoicingDocument?.status) ||
      invoiceStatus.includes(this.invoicingDocument?.status)
    );
  }

  get sharePrimaryAction() {
    return (
      this.invoicingDocument?.status === 'unpaid' || this.invoicingDocument?.status === 'canceled'
    );
  }

  @action createInvoiceFromQuote() {
    this.segment.track('quote_options_generate-invoice');
    this.router.transitionTo('receivable-invoices.new', {
      queryParams: { quoteId: this.invoicingDocument.id },
    });
  }

  @action openRemindersConfigurationModal() {
    let client = this.store.peekRecord(
      'client-hub',
      this.invoicingDocument.belongsTo('customer').id()
    );

    if (client?.hasReminders) {
      this.segment.track('invoice-details_edit-reminder_clicked');
    } else {
      this.segment.track('invoice-details_set-up-reminder_clicked');
    }

    let clientId = this.invoicingDocument.customer.id;
    this.router.transitionTo('clients.client.reminders-configuration', clientId);
  }

  @action openCancelModal() {
    this.segment.track('invoice_options_cancel');
    if (this.isFrenchOrganization) {
      this.modals.open(
        'receivable-invoices/cancel-invoice-modal-fr-org',
        {
          title: this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.title'),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.description-credit-note',
            {
              invoiceNumber: this.invoicingDocument.number,
            }
          ),
          cancelInvoiceWithCreditNoteTask: this.cancelInvoiceWithCreditNoteTask,
          cancelInvoiceWithCreditNoteLabel: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.cancel-with-credit-note'
          ),
          cancelInvoiceLabel: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.cancel'
          ),
          cancelInvoiceTask: this.cancelInvoiceTask,
        },
        {
          className: 'epm-popup-modal',
        }
      );
    } else {
      this.modals.open(
        'receivable-invoices/cancel-invoice-modal',
        {
          title: this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.title'),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.description',
            {
              invoiceNumber: this.invoicingDocument.number,
            }
          ),
          cancel: this.intl.t('btn.back'),
          confirm: this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.cancel'),
          confirmTask: this.cancelInvoiceTask,
        },
        {
          className: 'epm-popup-modal',
        }
      );
    }
  }

  cancelInvoiceWithCreditNoteTask = dropTask(async closeModal => {
    this.segment.track('invoice_cancel_with_credit_note_confirmed');
    this.transitionToCreditNoteForm();
    await closeModal();
  });

  @action createCreditNoteFromCanceledInvoice() {
    this.segment.track('invoice_option_create_credit_note_from_cancelled');
    this.transitionToCreditNoteForm();
  }

  @action
  cancelInvoiceWithCreditNote() {
    this.segment.track('invoice_options_create_credit_note');
    this.transitionToCreditNoteForm();
  }

  @action
  transitionToCreditNoteForm() {
    this.router.transitionTo('receivable-invoices.credit-notes.new', {
      queryParams: { invoiceId: this.invoicingDocument.id },
    });
  }

  cancelInvoiceTask = dropTask(async closeModal => {
    try {
      this.segment.track('invoice_cancel_confirmed');
      this.invoicingDocument.status = 'canceled';
      await this.invoicingDocument.save({ adapterOptions: { partial: true } });
      this.toastFlashMessages.toastInfo(
        this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.success', {
          invoiceNumber: this.invoicingDocument.number,
        })
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  markAsPaidInvoiceTask = dropTask(async closeModal => {
    try {
      this.segment.track('invoice_mark-as-paid_confirmed');
      this.invoicingDocument.status = 'paid';
      await this.invoicingDocument.save({ adapterOptions: { partial: true } });
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.mark-as-paid-modal.success', {
          invoiceNumber: this.invoicingDocument.number,
        })
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  @action handleEdit() {
    if (this.isQuote) {
      return this.handleEditQuote();
    }
    return this.handleEditDraft();
  }

  @action handleEditQuote() {
    this.segment.track('quote_options_edit');
    this.router.transitionTo('quotes.edit', this.invoicingDocument.id);
  }

  @action handleEditDraft() {
    this.segment.track('invoice-draft_options_edit');
    this.router.transitionTo('receivable-invoices.edit', this.invoicingDocument.id);
  }

  deleteDraftTask = dropTask(async closeModal => {
    try {
      await this.invoicingDocument.destroyRecord();
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  @action
  goToShareInvoice() {
    if (this.isQuote) {
      this.segment.track('quote_options_send');
      this.router.transitionTo('quotes.share', this.invoicingDocument.id);
      return;
    }
    this.segment.track(this.shareInvoiceEvent);
    this.router.transitionTo('receivable-invoices.share', this.invoicingDocument.id);
  }

  get shouldDisplayPaymentDate() {
    return this.invoicingDocument.status === STATUS.PAID;
  }

  get shareInvoiceEvent() {
    return {
      draft: 'invoice-draft_options_send',
      unpaid: 'invoice_options_send_invoice',
    }[this.invoicingDocument.status];
  }

  get cityAddress() {
    let billingAddress = this.invoicingDocument.customerSnapshot?.billingAddress;

    if (billingAddress.countryCode) {
      let country = this.intl.formatCountry(billingAddress.countryCode);
      return `${billingAddress.zipCode} ${billingAddress.city}, ${country}`;
    }
    return `${billingAddress.zipCode} ${billingAddress.city}`;
  }

  get deliveryCityAddress() {
    let deliveryAddress = this.invoicingDocument.customerSnapshot?.deliveryAddress;
    if (deliveryAddress.countryCode) {
      let country = this.intl.formatCountry(deliveryAddress.countryCode);
      return `${deliveryAddress.zipCode} ${deliveryAddress.city}, ${country}`;
    }
    return `${deliveryAddress.zipCode} ${deliveryAddress.city}`;
  }

  get goToShareInvoiceCTAText() {
    return {
      pending_approval: this.intl.t('receivable-invoices.invoice-modal.quotes.actions.send-quote'),
      approved: this.intl.t('receivable-invoices.invoice-modal.quotes.actions.send-quote'),
      draft: this.intl.t('receivable-invoices.invoice-modal.actions.share-draft'),
      unpaid: this.intl.t('receivable-invoices.invoice-modal.actions.send-invoice'),
    }[this.invoicingDocument.status];
  }

  get isEInvoicing() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  get finalizeDraftModalDescription() {
    let shouldDisplayItalyCopy = this.isEInvoicing;
    let isAutoNumbering = this.args.settings?.numberingMode === 'automatic';

    if (isAutoNumbering) {
      let { nextInvoiceNumber, invoiceNextNumberFormatted } = this.args.settings;
      let invoiceNumber = invoiceNextNumberFormatted || nextInvoiceNumber;
      return shouldDisplayItalyCopy
        ? this.intl.t('receivable-invoices.issue-modal.draft.description.italy.automatic', {
            invoiceNumber,
          })
        : this.intl.t('receivable-invoices.issue-modal.draft.description.automatic', {
            invoiceNumber,
          });
    }

    // French e-invoicing
    if (this.shouldDisplayEinvoicing) {
      return this.intl.t('receivable-invoices.issue-modal.einvocing-subtitle');
    }

    return shouldDisplayItalyCopy
      ? this.intl.t('receivable-invoices.issue-modal.draft.description.italy.manual')
      : this.intl.t('receivable-invoices.issue-modal.draft.description.manual');
  }

  get shouldDisplayCheckbox() {
    return (
      this.args.settings?.numberingMode === 'manual' &&
      safeLocalStorage.getItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY) !== 'true'
    );
  }

  get customerFrEinvoicingEnabled() {
    let customer = this.invoicingDocument?.belongsTo('customer');
    let client = this.store.peekRecord('client-hub', customer.id());

    return client ? client?.einvoicing : false;
  }

  // French e-invoicing
  get shouldDisplayEinvoicing() {
    return this.args?.canCreateFrEinvoice && this.customerFrEinvoicingEnabled;
  }

  @action openFinalizeDraftModal() {
    this.segment.track('invoice-draft_create-invoice');
    this.modals.open(
      'receivable-invoices/confirm-creation-modal',
      {
        title: this.intl.t('receivable-invoices.issue-modal.draft.title'),
        description: this.finalizeDraftModalDescription,
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('receivable-invoices.issue-modal.draft.cta.create-invoice'),
        confirmTask: this.checkIsCustomerUpdatedOrInvalidTask,
        shouldDisplayCheckbox: this.shouldDisplayCheckbox,
        shouldDisplayEinvoicing: this.shouldDisplayEinvoicing,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  }

  redirectToEditTask = dropTask(this, async closeModal => {
    this.router.transitionTo('receivable-invoices.edit', this.invoicingDocument.id);
    await closeModal?.();
  });

  checkIsCustomerUpdatedOrInvalidTask = dropTask(this, async closeModal => {
    let client;
    let customer = this.store.peekRecord(
      'customer',
      this.invoicingDocument.belongsTo('customer').id()
    );

    if (variation('feature--boolean-client-hub')) {
      client = this.store.peekRecord(
        'client-hub',
        this.invoicingDocument.belongsTo('customer').id()
      );
      await client?.validate();
    }

    let isClientMissing = !customer && !client;

    if (client?.validations.isInvalid) {
      await this.modals.open('receivable-invoices/invalid-client-modal', {
        title: this.intl.t('receivable-invoices.invoice-modal.draft-errors.invalid-client.title'),
        modalDescription: this.intl.t(
          'receivable-invoices.invoice-modal.draft-errors.invalid-client.description'
        ),
        confirm: this.intl.t(
          'receivable-invoices.invoice-modal.draft-errors.invalid-client.cta.update'
        ),
        cancel: this.intl.t('btn.cancel'),
        confirmTask: this.redirectToEditClientTask,
        client,
      });
      closeModal?.();
    } else if (customer && customer.get('updatedAt') > this.invoicingDocument.get('updatedAt')) {
      await this.modals.open('receivable-invoices/updated-customer-modal', {
        title: this.intl.t('receivable-invoices.invoice-modal.draft-errors.client-changed.title'),
        modalDescription: this.intl.t(
          'receivable-invoices.invoice-modal.draft-errors.client-changed.description',
          { amountDue: `${this.invoicingDocument.amountDue} ${customer.currency}` }
        ),
        edit: this.intl.t('receivable-invoices.invoice-modal.draft-errors.cta.edit-draft'),
        confirm: this.intl.t('btn.confirm'),
        customer,
        confirmTask: this.finalizeDraftTask,
        redirectTask: this.redirectToEditTask,
      });
      closeModal?.();
    } else {
      this.finalizeDraftTask
        .perform(closeModal, isClientMissing)
        .catch(error => this.handleError(error));
    }
  });

  finalizeDraftTask = dropTask(this, async (closeModal, isClientMissing) => {
    let oldInvoiceNumber = this.invoicingDocument.number;
    try {
      if (this.args.settings?.numberingMode === 'automatic' && !this.isQuote) {
        this.invoicingDocument.number = null;
      }
      await this.invoicingDocument.finalizeDraft();
      closeModal?.();

      let origin = 'receivable-invoices.draft-finalize';

      this.router.transitionTo('receivable-invoices.share', this.invoicingDocument.id, {
        queryParams: { origin },
      });

      this.toastFlashMessages.toastSuccess(this.intl.t('receivable-invoices.issue-modal.toaster'));
      this.segment.track('invoice-draft_create-invoice_confirm');
    } catch (error) {
      this.invoicingDocument.number = oldInvoiceNumber;
      closeModal?.();
      let modalParams;
      if (
        isClientMissing ||
        (error.status === 422 && this.invoicingDocument.errors.has('customer/data/id'))
      ) {
        modalParams = {
          title: this.intl.t(
            'receivable-invoices.invoice-modal.draft-errors.client-not-exists.title'
          ),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.draft-errors.client-not-exists.description'
          ),
          illustration: importAsset([
            '/illustrations/receivable-invoices/missing-customer-error.svg',
          ]),
        };
      } else if (error.status === 409) {
        modalParams = {
          title: this.intl.t('receivable-invoices.invoice-modal.draft-errors.number-exists.title'),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.draft-errors.number-exists.description'
          ),
          illustration: importAsset([
            '/illustrations/receivable-invoices/duplicated-invoice-number-error.svg',
          ]),
        };
      }

      if (modalParams) {
        await this.modals.open('receivable-invoices/finalize-error-modal', {
          cancel: this.intl.t('btn.cancel'),
          confirm: this.intl.t('receivable-invoices.invoice-modal.draft-errors.cta.edit-draft'),
          confirmTask: this.redirectToEditTask,
          ...modalParams,
        });
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } finally {
      if (this.shouldDisplayCheckbox) {
        safeLocalStorage.setItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY, 'true');
      }
    }
  });

  redirectToEditClientTask = dropTask(this, async (closeModal, client) => {
    this.router.transitionTo('clients.client.edit', client.id, {
      queryParams: { redirect: 'receivable-invoices.edit', redirectId: this.invoicingDocument.id },
    });
    await closeModal?.();
  });

  get isFrenchOrganization() {
    return this.organizationManager.organization.legalCountry === 'FR';
  }

  get shouldDisplayCreateCreditNoteCTA() {
    return !(
      this.isEInvoicing &&
      ['pending', 'declined'].includes(this.invoicingDocument.get('einvoicingStatus'))
    );
  }

  get shouldDisplayCreateCreditNoteWhenCanceled() {
    return (
      this.invoicingDocument.status === STATUS.CANCELED &&
      this.invoicingDocument.receivableCreditNotes?.length === 0
    );
  }

  get languageDescription() {
    let description = this.intl.t('receivable-invoices.invoice-modal.invoice-language', {
      language: this.invoicingDocument.locale ?? this.invoicingDocument.get('customer.locale'),
    });
    let [label, content] = description.split(':').map(string => string.trimStart());
    return { label: `${label}:`, content };
  }

  @action openApproveQuoteModal() {
    this.segment.track('quote_options_mark-as-approved');
    this.modals.open('receivable-invoices/mark-as-paid-modal', {
      title: this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.title'),
      description: this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.description'),
      cancel: this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.return'),
      confirm: this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.confirm'),
      confirmTask: this.approveQuoteTask,
    });
  }

  approveQuoteTask = dropTask(async closeModal => {
    this.segment.track('quote_mark-as-approved_confirmed');
    try {
      await this.invoicingDocument.updateQuoteStatus('approved');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.approve-quote-modal.success')
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal();
    }
  });

  @action openCancelQuoteModal() {
    this.segment.track('quote_options_cancel');
    this.modals.open(
      'receivable-invoices/cancel-invoice-modal',
      {
        title: this.intl.t('receivable-invoices.invoice-modal.cancel-quote-modal.title'),
        description: this.intl.t(
          'receivable-invoices.invoice-modal.cancel-quote-modal.description',
          {
            QuoteNumber: this.invoicingDocument.number,
          }
        ),
        cancel: this.intl.t('receivable-invoices.invoice-modal.cancel-quote-modal.return'),
        confirm: this.intl.t('receivable-invoices.invoice-modal.cancel-quote-modal.confirm'),
        confirmTask: this.cancelQuoteTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  }

  cancelQuoteTask = dropTask(async closeModal => {
    this.segment.track('quote_canceled_confirmed');
    try {
      await this.invoicingDocument.updateQuoteStatus('canceled');
      this.toastFlashMessages.toastInfo(
        this.intl.t('receivable-invoices.cancel-quote-modal.success', {
          QuoteNumber: this.invoicingDocument.number,
        })
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('quotes.index');
    }
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
