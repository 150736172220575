export default {
  "header-cell": "qCY",
  "header-content": "qCh caption-bold",
  "active": "qCf",
  "empty": "qCK",
  "align-right": "qCG",
  "type": "qCr",
  "requester": "qCb",
  "request-date": "qCy",
  "amount": "qCU",
  "status": "qCj"
};
