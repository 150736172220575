export default {
  "supplier-selector-wrapper": "qMi",
  "select-supplier": "qMA",
  "iban": "qMY",
  "card": "qMh body-2",
  "add-btn": "qMf btn btn--square btn--icon-only",
  "archived-card": "qMK",
  "title": "qMG body-1",
  "archived-title": "qMr",
  "edit-supplier-tooltip": "qMb",
  "add-supplier": "qMy btn btn--tertiary",
  "error": "qMU caption"
};
