export default {
  "header-cell": "qqm",
  "header-content": "qqp caption-bold",
  "active": "qqx",
  "empty": "qqw",
  "align-right": "qqN",
  "request-type": "qqv",
  "requester": "qqo",
  "amount": "qqn"
};
