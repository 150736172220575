export default {
  "sidepanel": "qlh",
  "close-icon": "qlf",
  "close-button": "qlK",
  "supplier-card": "qlG",
  "supplier-card-details": "qlr",
  "supplier-name": "qlb title-3 mb-8",
  "supplier-card-placeholder": "qly",
  "box": "qlU",
  "box-element": "qlj",
  "related-invoices-title": "qlW",
  "placeholder-container": "qlu",
  "related-invoices-wrapper": "qXS",
  "related-invoice-placeholder": "qXc",
  "related-invoice-placeholder-inner": "qXq",
  "actions": "qXZ",
  "currency": "qXR"
};
