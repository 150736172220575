export default {
  "header": "qST",
  "header-type": "qSz",
  "close": "qSa",
  "close-icon": "qSH",
  "header-infos": "qSO",
  "title": "qSm body-1",
  "declined": "qSp",
  "canceled": "qSx",
  "approved": "qSw",
  "pending": "qSN",
  "date": "qSv body-2",
  "mileage-icon": "qSo",
  "distance-subtitle": "qSn body-2",
  "link-icon": "qSi",
  "container": "qSA",
  "picto": "qSY",
  "general": "qSh",
  "amount": "qSf",
  "calculation-container": "qSK",
  "counterparty": "qSG body-2",
  "infobox": "qSr"
};
