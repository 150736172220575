export default {
  "refunds-total": "qtz",
  "switch-recurrence": "qta",
  "flex-wrapper": "qtH",
  "promo-code": "qtO",
  "promo-code-total": "qtm",
  "others": "qtp",
  "others-total": "qtx",
  "subscription-detail": "qtw",
  "total-vat": "qtN",
  "fee-description": "qtv"
};
