export default {
  "mileage-validation": "cyB",
  "actions": "cyE",
  "action-btn": "cyd",
  "action-btn-right": "cyI",
  "request-mileage-validation": "cyt",
  "account-selector": "cyM",
  "has-error": "cyP",
  "funds-disclaimer": "cyl",
  "account-options": "cyX",
  "account-balance": "cyC",
  "warnings": "cyk"
};
