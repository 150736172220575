export default {
  "mileage-validation": "cGq",
  "actions": "cGZ",
  "action-btn": "cGR",
  "action-btn-success": "cGQ",
  "action-btn-right": "cGe",
  "request-mileage-validation": "cGB",
  "account-selector": "cGE",
  "has-error": "cGd",
  "funds-disclaimer": "cGI",
  "account-options": "cGt",
  "account-balance": "cGM",
  "warnings": "cGP"
};
