export default {
  "wrapper": "cUi",
  "card-header": "cUA",
  "subtitle-caption": "cUY",
  "estimated-earnings": "cUh",
  "progress": "cUf",
  "progress-bar": "cUK",
  "progress-bar-text": "cUG",
  "subtitle": "cUr",
  "badge": "cUb",
  "core-information": "cUy",
  "secondary-information": "cUU",
  "remuneration-table": "cUj",
  "name": "cUW title-3",
  "separator": "cUu",
  "remuneration-line": "cjS",
  "german-disclaimer": "cjc",
  "table": "cjq",
  "table-right": "cjZ"
};
