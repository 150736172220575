export default {
  "modal": "qPw",
  "action-container": "qPN",
  "similar-label": "qPv",
  "info-icon": "qPo",
  "container": "qPn",
  "subtitle": "qPi caption-bold",
  "error": "qPA",
  "item-container": "qPY"
};
