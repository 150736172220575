export default {
  "bank-account-option": "qkV",
  "row": "qkL",
  "active": "qkT",
  "animated": "qkz",
  "fadein-item": "qka",
  "cell": "qkH body-2",
  "cell-content": "qkO",
  "subtitle": "qkm caption",
  "no-padding": "qkp",
  "empty": "qkx",
  "note": "qkw",
  "cell-content-amount": "qkN body-1",
  "cell-content-receipt": "qkv body-1",
  "cell-quick-actions": "qko",
  "account-selector": "qkn",
  "cell-type": "qki"
};
