export default {
  "row": "qZc",
  "active": "qZq",
  "dot": "qZZ",
  "red": "qZR",
  "green": "qZQ",
  "yellow": "qZe",
  "status": "qZB",
  "align-right": "qZE",
  "animated": "qZd",
  "fadein-item": "qZI",
  "cell": "qZt body-2",
  "cell-content": "qZM",
  "amount": "qZP body-1",
  "subtitle": "qZl caption",
  "no-padding": "qZX"
};
