export default {
  "cell": "qlJ",
  "cell-label-container": "qls",
  "cell-label": "qlF",
  "overflow": "qlg",
  "sub-label": "qlD body-2",
  "overdue": "qlV",
  "file-name-warning": "qlL",
  "missing": "qlT",
  "warning-icon": "qlz",
  "flex-label": "qla",
  "with-separator": "qlH",
  "self-invoice-icon": "qlO"
};
