export default {
  "subtitle": "cUz",
  "green-text": "cUa",
  "balance-subtitle": "cUH",
  "options": "cUO",
  "shadow": "cUm",
  "menu-item": "cUp body-2",
  "close-account": "cUx",
  "footer": "cUw",
  "progress": "cUN",
  "progress-bar": "cUv",
  "progress-bar-text": "cUo",
  "progress-active": "cUn"
};
