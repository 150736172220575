export default {
  "header-cell": "qCW",
  "header-content": "qCu caption-bold",
  "active": "qkS",
  "empty": "qkc",
  "type": "qkq",
  "requester": "qkZ",
  "request-date": "qkR",
  "amount": "qkQ",
  "receipt": "qke",
  "quick-actions": "qkB"
};
