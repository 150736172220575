export default {
  "header": "qte",
  "subtitle": "qtB body-2",
  "date": "qtE",
  "subtitle-error": "qtd",
  "card": "qtI",
  "card-header": "qtt",
  "line-placeholder": "qtM",
  "post-closing-list": "qtP",
  "post-closing-container": "qtl",
  "post-closing-list-element": "qtX",
  "free-trial-disclaimer": "qtC",
  "description": "qtk body-2",
  "mw-100": "qtJ",
  "addon-overview": "qts",
  "addons-container": "qtF"
};
