export default {
  "section-container": "cbD",
  "disclaimer-section": "cbV",
  "trip-title": "cbL caption-bold mb-16",
  "google-link-container": "cbT",
  "link-icon": "cbz",
  "trip-details": "cba body-2",
  "trip-details-icon": "cbH",
  "trip-details-title": "cbO",
  "subtitle": "cbm caption-bold mb-16",
  "disclaimer": "cbp",
  "visualizer": "cbx"
};
