export default {
  "header-cell": "qXd",
  "first-col": "qXI",
  "mid-col": "qXt",
  "outstanding-col": "qXM",
  "header-content": "qXP caption-bold",
  "active": "qXl",
  "quick-actions": "qXX",
  "empty": "qXC"
};
