export default {
  "row": "qZa",
  "active": "qZH",
  "dot": "qZO",
  "red": "qZm",
  "green": "qZp",
  "yellow": "qZx",
  "status": "qZw",
  "align-right": "qZN",
  "animated": "qZv",
  "fadein-item": "qZo",
  "cell": "qZn body-2",
  "cell-content": "qZi",
  "amount": "qZA body-2",
  "subtitle": "qZY caption",
  "padding-left": "qZh",
  "padding-right": "qZf",
  "note": "qZK"
};
